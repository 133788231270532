import { faArrowDown, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../css/aboutme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Aboutme() {
  return (
    <>
      <div className="landingaboutme">
        <h1>TRENER PERSONALNY</h1>
        <h3>WYKORZYSTAJ SWÓJ POTENCJAŁ DO MAKSIMUM</h3>
        <p>Razem osiągniemy Twoje cele. Profesjonalne treningi personalne w Katowicach, które przyniosą rezultaty.</p>
        <a href="#aboutme" style={{ textDecoration: 'none', color: 'inherit' }}>Więcej o mnie poniżej <FontAwesomeIcon icon={faArrowDown}/></a>
      </div>
      <div id="aboutme" className='aboutme'>
          <span>
            <img src="/images/aboutme.jpg" alt="twojtrener" />
          </span>
          <span>
            <h2>O MNIE</h2>
            <p>Cześć! Nazywam się Szymon i jestem Trenerem Personalnym specjalizującym się w nauce boksu oraz treningach dopasowanych do Twoich celów i możliwości. Pomogę Ci osiągnąć wymarzoną sylwetkę, poprawić kondycję oraz wprowadzić zdrowe nawyki treningowe.</p>

            <p>Jestem absolwentem Zespołu Szkół Sportowych im. Polskich Olimpijczyków, co dało mi solidne podstawy teoretyczne i praktyczne. Moje doświadczenie jako podopiecznego i trenera pozwala mi lepiej zrozumieć Twoje potrzeby i dostosować plan treningowy, by był skuteczny i bezpieczny.</p>

            <p>Wspólnie zadbamy o Twoją formę i zdrowie. Nauczę Cię zdrowych nawyków żywieniowych i pokażę, że nawet trenując tylko dwa razy w tygodniu, możesz osiągnąć swoje cele. Nie czekaj – zacznij już dziś i przekonaj się, jak wiele możesz osiągnąć!</p>
            {/* <p>Cześć, nazywam się Szymon i jestem Trenerem Personalnym. Jestem absolwentem Zespołu Szkół Sportowych im.polskich olimpijczyków. Bazując na własnym doświadczeniu i wiedzy zdobytej na szkoleniach wiem jak w zdrowy sposób podejść do treningu.</p>
            <p>Nauczę Cię zdrowych nawyków żywieniowych i pokażę, że trenując, tylko dwa razy w tygodniu jesteś w stanie osiągnąć swoja wymarzoną sylwetkę. Sam kiedyś byłem podopiecznym, dlatego tym bardziej jestem w stanie zrozumieć Twoje potrzeby i cele wobec treningu. Nie czekaj i zacznij już dziś!</p> */}
            <a href="tel:536979286"><p className='tel'><FontAwesomeIcon icon={faPhone}/> 536 979 286</p></a>
          </span>
      </div>
    </>
  );
}


// Cześć! Nazywam się Szymon i jestem Trenerem Personalnym. Jestem absolwentem Zespołu Szkół Sportowych im.polskich olimpijczyków. Bazując na własnym doświadczeniu i wiedzy zdobytej na szkoleniach wiem jak w zdrowy sposób podejść do treningu.
//       Nauczę Cię zdrowych nawyków żywieniowych i pokażę, że trenując, tylko dwa razy w tygodniu jesteś w stanie osiągnąć swoja wymarzoną sylwetkę. Sam kiedyś byłem podopiecznym, dlatego tym bardziej jestem w stanie zrozumieć Twoje potrzeby i cele wobec treningu. Nie czekaj i zacznij już dziś!
//       pod tym moj numer telefonu 536979286


// TRENER PERSONALNY
// Wykorzystaj swój potencjał do maksimum
// Razem osiągniemy Twoje cele. Profesjonalne treningi personalne w Katowicach, które przyniosą rezultaty.

